import React from 'react';
import Layout from '../layouts/Layout';
import { OrganizationModel } from '@nimles/models/lib/organization-management';
import { getImageUrl } from '../utils';
import queryString from 'query-string';
import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { ApolloProvider, gql, useQuery } from '@apollo/react-hooks';
import { OrganizationPage } from '../components/organization/page/OrganizationPage';

export const client: any = new ApolloClient({
  uri: 'https://api.nimles.com/organization-management/public/graphql',
  fetch,
});

const organizationQuery = gql`
  query GetOrganization($tenantId: Uuid!, $id: Uuid!) {
    organization(tenantId: $tenantId, id: $id) {
      edges {
        node {
          id
          name
          description
          uniqueName
          phoneNumbers
          emails
          website
          bannerId
          logoId
          address {
            id
            street
            postalCode
            city
            state
          }
          location {
            id
            latitude
            longitude
          }
          socialLinks
          tags
        }
      }
    }
  }
`;

interface Props {
  organization: OrganizationModel;
  organizationsNearby: OrganizationModel[];
  location: Location;
}

const Organization = ({
  organization,
  organizationsNearby,
  location,
}: Props) => {
  const { draft }: any = location
    ? queryString.parse(location.search)
    : { draft: false };

  const { data } = useQuery<{ organization: OrganizationModel }>(
    organizationQuery,
    {
      variables: { tenantId: process.env.GATSBY_TENANT, id: organization?.id },
      skip: !draft,
    }
  );

  if (data?.organization) {
    organization = data.organization || organization;
  }

  return (
    <OrganizationPage
      organization={organization}
      organizationsNearby={organizationsNearby}
      location={location}
    />
  );
};
const OrganizationTemplate = ({ pageContext, location }) => {
  const {
    organization,
    organizationsNearby,
  }: {
    organization: OrganizationModel;
    organizationsNearby: OrganizationModel[];
  } = pageContext;
  const { name, summary, bannerId, tags } = organization;

  const banner = getImageUrl(bannerId, 1920);

  const meta = {
    title: name,
    description: summary,
    image: banner,
    keywords: tags?.join(', '),
  };

  return (
    <ApolloProvider client={client}>
      <Layout
        header
        footer
        meta={meta}
        location={location}
        headerAlwaysOpen={true}
      >
        <Organization
          organization={organization}
          location={location}
          organizationsNearby={organizationsNearby}
        />
      </Layout>
    </ApolloProvider>
  );
};

export default OrganizationTemplate;
